<template>
  <div class="waivers-index">
    <div class="container">
      <table class="waivers-table">
        <thead>
          <tr>
            <th>{{ $t('Signed on') }}</th>
            <th>{{ $t('Email') }}</th>
            <th>{{ $t('Name') }}</th>
            <th>{{ $t('Language') }}</th>
            <th>{{ $t('Marketing consent') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="waiver in waivers" :key="waiver.id">
            <td>{{ formatDate(waiver.createdAt) }}</td>
            <td>{{ waiver.firstName }} {{ waiver.lastName }}</td>
            <td><a class="link-style" :href="'mailto:' + waiver.email">{{ waiver.email }}</a></td>
            <td>{{ waiver.waiverContent.language ? waiver.waiverContent.language : '*' }}</td>
            <td class="icon"><svg><use v-bind:xlink:href="marketingConsentIcon(waiver)"></use></svg></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIGridIndexLoader from '@/core/shared/components/ui/UIGridIndexLoader';
import moment from 'moment-timezone';

@Component({
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    UIButton,
    UIGridIndexLoader,
  },
})
export default class WaiversIndexRoute extends Vue {
  loading = true;

  get isLoading () {
    return this.loading && this.waivers.length === 0;
  }

  get waivers () {
    return this.$store.getters['entities/Waiver/all']();
  }

  marketingConsentIcon (waiver) {
    if (waiver.consentMarketing) {
      return '#icon-check';
    }
    return '#icon-x';
  }

  localStartTime (waiver) {
    return moment.utc(waiver.createdAt);
  }

  formatDate (date) {
    return moment(date).format('LL');
  }

  async created () {
    await this.$store.dispatch('getSignedWaivers', this.locationId);
    this.loading = false;
    this.$store.commit('setTitle', this.$t('Signed Waivers'));
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.waivers-index {
  padding-bottom: 7rem;

  & .container {
    @apply --container;
  }

  & h2 {
    margin-top: var(--spacingSm);
  }

  & .waivers-table {
    @apply --f6;
    border-collapse: collapse;
    border-radius: var(--radius);
    overflow: hidden;
    background-color: var(--colorWhite);
    box-shadow: var(--shadowSm);
    margin: var(--spacingSm) 0;
    width: 100%;

    & thead tr {
      background-color: var(--colorStorm);
      color: #ffffff;
      text-align: left;
      border-radius: var(--radius);
    }

    & th,
    & td {
      padding: var(--spacingSm);
    }
  }

  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  & .icon {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}

</style>
